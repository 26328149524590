import { fallback, } from 'utils/utils';
import { Box } from '@mui/material';
import _ from 'lodash';
import { RenderValue } from 'components/render';
import { useFieldInfo } from 'contexts/FieldInfoContext';

const MultipleTableBasedOn = () => {
  const { fieldProps, info } = useFieldInfo()
  const rows                 = fallback(fieldProps.value, [{}])
  const openBasedOn          = info.field.mode === "open" && rows.length > 0 && rows[0].__basedOn || {}

  return openBasedOn && !_.isEmpty(openBasedOn)
    ? <Box id={"based-on-content"} marginBottom={"10px"} >
        <RenderValue>
          {openBasedOn}
        </RenderValue>
      </Box>
    : null
}

export default MultipleTableBasedOn
