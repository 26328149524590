import { getEventValue, validateField, workaroundFieldLabelOverlap } from "components/form/utils/validate-utils";
import { TextField } from "@mui/material";
import { fieldMinWidthStyle } from "components/form/utils/field-utils";

import InputError from "components/form/fields/InputError";
import { useFieldInfo } from "contexts/FieldInfoContext";
import { useFormInfo } from "hooks/form";
import { useAutoSubmitSignal } from "hooks/autosubmit";
import { useConfig } from "hooks/config";

const InputIntegerFieldContent = (props) => {
  const { setFocus }                   = props
  const { fieldProps, augProps, info } = useFieldInfo()
  const formInfo                       = useFormInfo()
  const {signal}                       = useAutoSubmitSignal()
  const {props: {taskRendering}}       = useConfig()

  function handleValidate(e) {
    const error = validateField("integer", fieldProps.required, e)
    augProps.setError(error)
  }

  function handleChange (e) {
    console.log("Handle change to %s", getEventValue(e))
    fieldProps.onChange(e)
    handleValidate(e)
	}

  function handleBlur (e) {
    setFocus(false)
    fieldProps.onBlur(e)
    handleValidate(e)
    signal()
  }

  function handleFocus(e) {
    setFocus(true)
    fieldProps.onFocus(e)
  }

  const workaround = workaroundFieldLabelOverlap(info.rpath)
  return (
    <TextField
      {...fieldProps}
      {...workaround}

      onWheel={e => e.target.blur()}

      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      type="number"
      
      style={taskRendering == 'standard' ? fieldMinWidthStyle(formInfo, info.field, taskRendering) : undefined}

      fullWidth
    />
  )
}

const InputIntegerField = (props) => (
  <InputError>
    <InputIntegerFieldContent />
  </InputError>
)

export default InputIntegerField
